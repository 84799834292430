"use strict";
import AccessControl from './api/access_control.js';
import Activity from './api/activity.js';
import Admin from './api/admin.js';
import Application from './api/applications.js';
import Billing from './api/billing.js';
import Category from './api/categories.js';
import Contacts from './api/contacts.js';
import Dashboard from './api/dashboard.js';
import Devportal from './api/devportal.js';
import DocumentBatches from './api/document_batches.js';
import Documents from './api/documents.js';
import Events from './api/events.js';
import General from './api/general.js';
import Insurance from './api/insurance.js';
import Invoices from './api/invoices.js';
import Lease from './api/lease.js';
import Maintenance from './api/maintenance.js';
import MaintenanceExtras from './api/maintenance_extras.js';
import MaintenanceTypes from './api/maintenance_types.js';
import Methods from './api/methods.js';
import Leads from './api/leads.js';
import Notes from './api/notes.js';
import Notifications from './api/notifications.js';
import Payment from './api/payment.js';
import Products from './api/products.js';
// import Promotions from './api/promotions.js';
import Properties from './api/properties.js';
import PropertyMap from "./api/property_map.js";
import Reports from './api/reports.js';
import Reservations from './api/reservations.js';
import Services from './api/services.js';
import Settings from './api/settings.js';
import Templates from './api/templates.js';
import Tenants from './api/tenants.js';
import Todos from './api/todos.js';
import Triggers from './api/triggers.js';
import Units from './api/units.js';
import Users from './api/users.js';
import Uploads from './api/uploads.js';
import Vendors from './api/vendors.js';
import Interactions from './api/interactions.js'
import RateManagement from './api/rate_management'
import RateManagementNew from './api/revenue_management/rate_management.js'
import TaskCenter from './api/task_center.js'
import Auctions from "./api/auctions.js";
import TaxProfile from './api/tax_profile.js'
import TenantPaymentsApplications from './api/tenant_payments_applications.js'
import SpaceMix from './api/space-mix.js'
import Onboarding from './api/onboarding.js'
import Accounting from './api/accounting'
import Spaces from './api/spaces.js';
import SpaceManagement from './api/space_management.js';
import reportLibrary from './api/report_library.js';
import RentManagement from './api/revenue_management/rent_management.js';
import Mailhouses from './api/mailhouse.js';
import Charm from './api/charm.js';
import DeliverMethods from './api/delivery_methods.js';
import PromotionsManagement from './api/revenue_management/promotions_management.js';
import CommunicationTemplate from './api/communication_template.js';
import Communications from './api/communications.js';
import Merchandise from './api/merchandise.js';
import PropertyGroups from './api/property_groups.js';
import NationalAccounts from './api/national_accounts.js';
import Automation from './api/automation.js';
import ApwManagement from './api/apw_management.js';
import Autocomplete from './api/autocomplete.js';

import Suburbs from './api/suburbs.js';
import Entities from './api/entities.js';
import MarketRegions from './api/market_regions.js';

export default Object.assign(
	AccessControl,
	Activity,
	Admin,
	Application,
	Auctions,
	Billing,
	Category,
	Contacts,
	Dashboard,
	Devportal,
	DocumentBatches,
	Documents,
	Events,
	General,
	Insurance,
	Invoices,
	Interactions,
	Leads,
	Lease,
	Mailhouses,
	Maintenance,
	MaintenanceExtras,
	MaintenanceTypes,
	Methods,
	Notes,
	Notifications,
	Payment,
	Products,
	// Promotions,
	Properties,
	PropertyMap,
	Reports,
	Reservations,
	Services,
	Settings,
	Templates,
	Tenants,
	TenantPaymentsApplications, 
	Todos,
	Triggers,
	Units,
	Users,
	Uploads,
	Vendors,
	RateManagement,
	TaskCenter,
	TaxProfile,
	Auctions,
	SpaceMix,
	Accounting,
	Spaces,
	SpaceManagement,
	Onboarding,
	reportLibrary,
	RateManagementNew,
	Charm,
	RentManagement,
	DeliverMethods,
	CommunicationTemplate,
	Communications,
	PropertyGroups,
	NationalAccounts,
	PromotionsManagement,
	Merchandise,
	Automation,
	ApwManagement,
	Autocomplete,
	Suburbs,
	Entities,
	MarketRegions
)


