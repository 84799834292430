import api from '../../assets/api.js';
import Vue from "vue";

class FilterData {
  constructor() {
    this.namespaced = true;
    this.state = {
      unit_floor: [],
      unit_size: [],
      rent_change_deployment_month: [],
      selectedProperty: '',
      properties: [],
      categories: [],
      unit_types: [],
      lead_sources: [],
      lease_standings: [],
      promotions: [],
      report_structures: {},
      admins: [],
      event_types: [],
      products: [],
      maintenance_types: [],
      activeCategory: {},
      unit_amenities: [],
      active_filters: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      lease_status: [
        { id: "active", name: "Active" },
        { id: "exempt", name: "Exempt" },
        { id: "no-status", name: "No Status"}
        // { id: "Inactive", name: "Inactive"},
      ],
      lead_converted_to_rental_by: [],
      lead_converted_to_reservation_by: [],
      lead_created_by_name: [],
      lead_unit_interest_type: []
    }
    this.getters = {
      unit_floor(state){
        return state.unit_floor;
      },
      unit_size(state){
        return state.unit_size;
      },
      rent_change_deployment_month(state) {
        return state.rent_change_deployment_month
      },
      selectedProperty(state) {
        return state.selectedProperty
      },
      property_id(state){
        return state.properties;
      },
      product_id(state){
        return state.products;
      },
      unit_category(state){
        return state.categories;
      },
      lead_category_name(state){
        return state.categories;
      },
      lead_category(state){
        return state.categories;
      },
      lead_last_contacted_by(state){
        return state.admins;
      },
      task_created_by(state){
        return state.admins;
      },
      task_assigned_to(state){
        return state.admins;
      },
      unit_category_id(state){
        return state.categories;
      },
      unit_active_category(state){
        return state.activeCategory;
      },
      unit_type(state){
        return state.unit_types;
      },
      lead_source(state){
        return state.lead_sources;
      },
      lease_standing(state){
        return state.lease_standings;
      },
      lease_sign_up_promo_id(state){
        return state.promotions;
      },
      report_structures(state){
        return state.report_structures
      },
      promotions(state){
        return state.promotions
      },
      admins(state){
        return state.admins
      },
      event_types(state){
        return state.event_types
      },
      products(state){
        return state.products
      },
      maintenance_type(state){
        console.log("getters", state.maintenance_types)
        return state.maintenance_types
      },
      unit_amenities(state) {
        return state.unit_amenities;
      },
      spacegroup_rate_management_active(state) {
        return state.active_filters
      },
      spacegroup_promotion_management_active(state) {
        return state.active_filters
      },
      promotion_active(state) {
        return state.active_filters
      },
      spacegroup_rate_plan_name(state, getters, rootState) {
        return rootState.revManStore.rate.plans?.map(plan => ({
          id: plan.name,
          name: plan.name
        })) ?? []
      },
      //to fetch filter data for space types in rate management
      spacegroup_spacetype(state) { 
        return state.unit_types;
      },
      tenant_selected_rent_plan(state, getters, rootState) {
        let plans = rootState.revManStore.rent.plans?.map(plan => ({
          id: plan.name,
          name: plan.name
        })) ?? []
        return [
          ...plans,
          {
            id: "-",
            name: "No Rent Plan",
          },
        ];
      },
      tenant_active(state) {
        return state.active_filters
      },
      tenant_lease_standing(state){
        return state.lease_standings;
      },
      lease_rent_plan_status(state) {
        return state.lease_status
      },
      lead_converted_to_reservation_by(state){
        return state.lead_converted_to_reservation_by
      },
      lead_converted_to_rental_by(state){
        return state.lead_converted_to_rental_by
      },
      lead_created_by_name(state){
        return state.lead_created_by_name
      },
      lead_unit_interest_type(state){
        return state.lead_unit_interest_type
      }
    };

    this.mutations = {
      setFloors(state, payload){
        state.unit_floor = payload;
      },
      setSizes(state, payload){
        state.unit_size = payload;
      },
      setDeploymentMonth(state, payload) {
        state.rent_change_deployment_month = payload
      },
      setProperties(state, payload){
        state.properties = payload;
      },
      setSelectedProperty(state, payload) {
        state.selectedProperty = payload
      },
      setCategories(state, payload){
        state.categories = payload;
      },
      setActiveCategory(state, payload){
        let activeCategory = state.categories.find(c => c.id === payload.id);
        state.activeCategory = activeCategory || null;
      },
      setProducts(state, payload){
        state.products = payload;
      },
      setUnitTypes(state, payload){
        state.unit_types = payload;
      },
      setLeadSources(state, payload){
        state.lead_sources = payload;
      },
      setLeaseStandings(state, payload){
        state.lease_standings = payload;
      },
      setPromotions(state, payload){
        state.promotions = payload;
      },
      setAdmins(state, payload){
        state.admins = payload.map(admin => {
          return {
           id: admin.contact_id,
           name:  admin.first + ' ' + admin.last
          }
        });
      },
      setEventTypes(state, payload){
        state.event_types = payload;
      },
      setMaintenanceTypes(state, payload){
        state.maintenance_types = payload;
        console.log('maintenance_types', state.maintenance_types);
      },
      setUnitAmenities(state, payload){
        state.unit_amenities = payload;
      },
      setLeadConvertedToRentalBy(state, payload){
        state.lead_converted_to_rental_by = payload
      },
      setLeadConvertedToReservedBy(state, payload){
        state.lead_converted_to_reservation_by = payload
      },
      setLeadCreatedByName(state, payload){
        state.lead_created_by_name = payload;
      },
      setSpaceInterestType(state, payload){
        state.lead_unit_interest_type = payload;
      }
    };

    this.actions =  {
      async getFloors({getters, commit}){
        if(!getters.unit_floor.length){
          let response = await api.get(this.$app, api.UNITS + 'floors');
          let floors = response.floors.map(f => {
            return {
              id: f.name.toString(),
              name: f.name.toString()
            }
          });
          commit('setFloors', floors);
        }
      },

      async getUnitAmenities({getters, commit}){
        if(!getters.unit_amenities.length){
          let response = await api.get(Vue, api.UNITS + 'amenities/all');
          let amenities = response.amenities.map(f => {
            return {
              id: f.name.toString(),
              name: f.name.toString()
            }
          });
          commit('setUnitAmenities', amenities);
        }
      },

      async getSizes({getters, commit}){
        if(!getters.unit_size.length){
          let response = await api.get(this.$app, api.UNITS + 'sizes');
          let sizes = response.sizes.map(s => {
            return {
              id: s.name.toString(),
              name: s.name.toString()
            }
          });
          commit('setSizes', sizes);
        }
      },

      async getSelectedProperty({commit}, property) {
        commit('setSelectedProperty', property)
      },

      async getDeploymentMonths({getters, commit}) {
        let url = api.getPropertyRentManagementUrl(getters.selectedProperty) + "rent-change/deployment-months"
        let response = await api.get(this.$app, url)
        let deploymentMonths = 
        response.rent_change_deployment_months 
          ? response.rent_change_deployment_months.map(m => {
              return {
                id: m.deployment_month.toString(),
                name: m.deployment_month.toString()
              }
            })
          : []   
        commit('setDeploymentMonth', deploymentMonths)
      },

      // BCT: clear the stored sizes on selection of another property -- HB-759
      clearSizes({commit}) {
        commit('setSizes', []);
      },

      async getProperties({getters, commit}){
        if(!getters.property_id.length){
          let response = await api.get(this.$app, api.PROPERTIES + 'list');
          let properties = response.properties.map(p => {
            return {
              id: p.id,
              name: p.address
            }
          });
          commit('setProperties', properties);
        }
      },
      async getCategories({getters, commit}){
          let response = await api.get(this.$app, api.CATEGORIES + 'list');
          commit('setCategories', response.categories);
      },

      async getCategoriesForProperty({commit}, payload){
        let response = await api.get(this.$app, api.CATEGORIES + 'list', { property_id: payload.property_id });
        await commit('setCategories', response.categories);
      },

      async getActiveCategory({getters, commit}, payload){
        await commit('setActiveCategory', payload);
      },

      async getProducts({getters, commit}){
        if(!getters.products.length) {
          let response = await api.get(this.$app, api.PRODUCTS);
          commit('setProducts', response.products);
        }
      },

      async getLeadSources({getters, commit}){
        if(!getters.lead_source.length) {
          let response = await api.get(this.$app, api.LEADS + '/sources');
          let lead_sources = response.sources.map(s => {
            return {
              id: s.source,
              name: s.source
            }
          });
          commit('setLeadSources', lead_sources);
        }
      },
      async getAdmins({getters, commit}){
        if(!getters.admins.length) {
          let response = await api.get(this.$app, api.ADMIN);
          commit('setAdmins', response.admins);

        }
      },

      async getContactMethods({getters, commit}){
      },

      async getLeaseStandings({getters, commit}){
        if(!getters.lease_standing.length) {
          let response = await api.get(this.$app, api.LEASES + '/standings');
          commit('setLeaseStandings', response.standings.filter(ls => ls.status));
        }
      },

      async getPromotions({getters, commit}){
        if(!getters.promotions.length) {
          let response = await api.get(this.$app,  api.PROMOTIONS);
          commit('setPromotions', response.promotions);
        }

      },
      async getPaymentSources({getters, commit}){


      },
      async getApplicationStatuses({getters, commit}){
      },

      async getMaintenanceTypes({getters, commit}){
        if(!getters.maintenance_type.length) {
          let r = await api.get(this.$app, api.MAINTENANCE + 'types');

          commit('setMaintenanceTypes', r.types);

        }
      },

      async getPaymentStatuses({getters, commit}){

      },
      async getProductTypes({getters, commit}){

      },

      async getUnitTypes({getters, commit},data){
        if(!data){
          data = JSON.parse( localStorage.getItem('hb_space_types')  || "{}");
        }

        let unit_types = data.map(unit_type => {
          return {
            id: unit_type.unit_type_id,
            name: unit_type.display_name,
          }
        });

        let lead_unit_type = data.map(lead_unit_type => {
          return {
            id: lead_unit_type.unit_type_name,
            name: lead_unit_type.display_name,
          }
        });


        commit('setUnitTypes', unit_types);
        commit('setSpaceInterestType', lead_unit_type);
      },
      
      async getEventTypes({getters, commit}){
        if(!getters.event_types.length) {
          commit('setEventTypes',  r.event_types);
        }
      },
      async getLeadConvertedToRentalBy({getters, commit}){
        // should a check be added before getting the data?
        // if(!getters.lead_converted_to_rental_by.length){
          // api to get the data and set in store
          let response = await api.get(this.$app, api.LEADS + '/converted-by-rental');
          // set data to store
          let convertedByRental = response.convertedByRental.map(s => {
            return {
              id: s.name,
              name: s.name
            }
          });
          commit('setLeadConvertedToRentalBy',convertedByRental)
          // }
        },
        async getLeadConvertedToReservedBy({getters, commit}){
          // should a check be added before getting the data?
          // if(!getters.lead_converted_to_reservation_by.length){
            // api to get the data and set in store
            let response = await api.get(this.$app, api.LEADS + '/converted-by-reservation');
            let convertedByReservation = response.convertedByReservation.map(s => {
              return {
                id: s.name,
                name: s.name
              }
            });
            // set data to store
            commit('setLeadConvertedToReservedBy',convertedByReservation)
          
        // }
      },
      async getLeadCreatedByName({getters, commit}){
        if(!getters.lead_created_by_name.length){
          let response = await api.get(this.$app, api.LEADS + 'lead-created-by-name');
          
          let lead_created_by_name = response.lead_created_by_name.map(s => {
            return {
              id: s.lead_created_by_name,
              name: s.lead_created_by_name
            }
          });
          commit('setLeadCreatedByName', lead_created_by_name);
        }
     }
    }
  }
}




export default FilterData;
