const types = {
  SET_TRIGGER_MERGE_FIELDS: "SET_TRIGGER_MERGE_FIELDS",
  ADD_MERGE_BUTTON_CLICK_FLAG: "ADD_MERGE_BUTTON_CLICK_FLAG",
  SET_SELECTED_MERGE_FIELD: "SET_SELECTED_MERGE_FIELD",
  SET_ACTION_SOURCE: "SET_ACTION_SOURCE",
  SET_FILTER_INPUT: "SET_FILTER_INPUT",
  SET_RESET_STATE: "SET_RESET_STATE",
};

class AddMergeFields {
  constructor() {
    this.namespaced = true;
    this.state = {
      triggerMergeFields: false,
      addMergeButtonClickFlag: false,
      selectedMergeField: null,
      actionSource: null,
      filterInput: null,
    };

    this.getters = {
      selectedMergeField: (state) => state.selectedMergeField,
      addMergeButtonClickFlag: (state) => state.addMergeButtonClickFlag,
      triggerMergeFields: (state) => state.triggerMergeFields,
      actionSource: (state) => state.actionSource,
      filterInput: (state) => state.filterInput,
    };

    this.actions = {
      setTriggerMergeFields(
        { commit, dispatch },
        { actionSource, addMergeButtonClickFlag }
      ) {
        dispatch("reset");
        commit(types.SET_TRIGGER_MERGE_FIELDS, true);
        commit(types.SET_ACTION_SOURCE, actionSource);
        commit(types.ADD_MERGE_BUTTON_CLICK_FLAG, addMergeButtonClickFlag);
      },
      setSelectedMergeField({ commit }, payload) {
        commit(types.SET_SELECTED_MERGE_FIELD, payload.selectedMergeField);
      },
      setFilterInput({ commit }, payload) {
        commit(types.SET_FILTER_INPUT, payload.filterInput);
      },
      reset({ commit }) {
        commit(types.SET_ACTION_SOURCE, null);
        commit(types.SET_TRIGGER_MERGE_FIELDS, false);
      },
    };

    this.mutations = {
      [types.SET_TRIGGER_MERGE_FIELDS](state, payload) {
        state.triggerMergeFields = payload;
      },
      [types.ADD_MERGE_BUTTON_CLICK_FLAG](state, payload) {
        state.addMergeButtonClickFlag = payload;
      },
      [types.SET_ACTION_SOURCE](state, payload) {
        state.actionSource = payload;
      },
      [types.SET_SELECTED_MERGE_FIELD](state, payload) {
        state.selectedMergeField = payload;
        console.log("state.selectedMergeField", state.selectedMergeField)
      },
      [types.SET_FILTER_INPUT](state, payload) {
        state.filterInput = payload;
      },
    };
  }
}
export default AddMergeFields;
